$(async function() {
	if(!window.vicinft){
		$('body').append('<script src="https://fm2.vicinft.com/bw/bw.min.js"></script>');
	}

	let label = $('#vici_login_script').data('label');
	let link = $('#vici_login_script').data('link');
	let authServer = $('#vici_login_script').data('auth');
	$('#vici_login_script').after('<button id="vici_login_button"></button>');
	$('#vici_login_script').remove();
	$('#vici_login_button').text(label);

	$(document).on("click","#vici_login_button", async function() {
		while(!window.vicinft){await sleep(100);}
		let breadwinner = await window.vicinft.getProvider();
		provider = breadwinner.provider;
		let at = await getToken(authServer);
		if(at){
			setCookie("vici_token", at.access_token, 1);
			window.location = link;
			// let ajaxConfig={
			// 	url: link,
			// 	type: 'get',
			// 	headers: {'Authorization': "Bearer "+at.access_token}
			// }
			// let res = await $.ajax(ajaxConfig);
			// let accounts = await provider.request({method: 'eth_requestAccounts'});
			// res = res.replace('0xdummy_wallet_here', accounts[0]);
			// document.getElementsByTagName("html")[0].innerHTML = res;
			// // var newDoc = window.open("text/html", "_self");
			// // newDoc.write(res);
			// // newDoc.close();
		}
	})
});

function setCookie(name,value,days) {
	var expires = "";
	if (days) {
		var date = new Date();
		date.setTime(date.getTime() + (days*24*60*60*1000));
		expires = "; expires=" + date.toUTCString();
	}
	document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

function sleep(ms) {
	return new Promise(resolve => setTimeout(resolve, ms));
}

async function getToken(authServer){
	let accounts = await provider.request({method: 'eth_requestAccounts'});
	//const authServer = 'https://fm2.vicinft.com/auth/login/600form';
	console.log("Fetching access token from "+authServer);
	var signedMessage = undefined;
	try{
		signedMessage = await signMessage();
	}catch(e){
		console.log(e)
	}
	if(signedMessage){
		try {
			let ajaxConfig={
				url: authServer,
				type: 'POST',
				headers: {'Content-Type': 'application/json'}
			}

			ajaxConfig.data = JSON.stringify({
				data: signedMessage.data,
				signature: signedMessage.sig,
				userid: accounts[0]
			});
			result = await $.ajax(ajaxConfig);
			if(!result.access_token){
				alert('You do not have the required NFT to access this page');
				return false;
			}
			return result;
		} catch (error) {
			console.error(error);
			return error.status
		}
	}else{
		let res = {result: false};
		return res;
	}
}

async function signMessage(){
	let accounts = await provider.request({method: 'eth_requestAccounts'});
	const domain = [
		{ name: "name", type: "string" },
		{ name: "version", type: "string" },
		{ name: "chainId", type: "uint256" },
		{ name: "verifyingContract", type: "address" },
	];

	const login = [
		{ name: "userid", type: "address" },
		{ name: "comid", type: "string" },
		{ name: "destination", type: "string" },
		{ name: "description", type: "string" }
	];
	const domainData = {
		name: "ViciNFT access check",
		version: "2",
		chainId: String(window.vicinft.config.chainId),
		verifyingContract: "0x0000000000000000000000000000000000000000"
	};

	let message = {
		userid: accounts[0],
		comid: "",
		destination: 'ViciNFT access check',
		description: 'Check to ensure you can access resource'
	};

	data = JSON.stringify({
		types: {
			EIP712Domain: domain,
			Login : login
		},
		domain: domainData,
		primaryType: "Login",
		message: message
	});
	sig = await provider.request({
		method: "eth_signTypedData_v3",
		params: [accounts[0], data]
	}).catch((error) => {
		alert("There was an issue with your wallet's signature. Please refresh the page and try again.", "error");
	});

	return({data: data, sig: sig});
}

function jwtDecode(t) {
	let token = {};
	token = JSON.parse(window.atob(t.split('.')[1]));
	return (token)
}